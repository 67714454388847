import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './MaterialSearch.css';
import axios from 'axios';
import FileList from './FileList';
import UserIconSample from '../assets/iconsample.png';

const MaterialSearch = () => {
  const { searchKeyword } = useParams();
  const [schoolType, setSchoolType] = useState('');
  const [subject, setSubject] = useState('');
  const [fileType, setFileType] = useState('');
  const [keyword, setKeyword] = useState(searchKeyword || '');
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [error, setError] = useState('');
  const resultsRef = useRef(null);
  const searchContainerRef = useRef(null);

  const filesPerPage = 9;

  const schoolTypes = ['小学校', '中学校', '高等学校', 'その他'];
  const subjects = ['国語', '社会', '地歴公民', '公民', '数学・算数', '理科', '生活', '音楽', '芸術', '図画工作', '技術家庭', '家庭', '保健体育', '外国語活動', '外国語', '専門科目'];
  const fileTypes = ['PowerPoint', 'PDF', 'Word', 'Excel', '画像形式'];

  const handleSearch = useCallback(() => {
    let newFilteredFiles = [...files];
    if (schoolType) {
      newFilteredFiles = newFilteredFiles.filter((file) => file.school_type === schoolType);
    }
    if (subject) {
      newFilteredFiles = newFilteredFiles.filter((file) => file.subject === subject);
    }
    if (fileType) {
      newFilteredFiles = newFilteredFiles.filter((file) => file.file_type === fileType);
    }

    const searchWord = keyword.trim().toLowerCase();
    if (searchWord) {
      newFilteredFiles = newFilteredFiles.filter((file) =>
        file.filename && file.filename.toLowerCase().includes(searchWord)
      );
    }

    setFilteredFiles(newFilteredFiles);
    setCurrentPageIndex(0);
  }, [files, schoolType, subject, fileType, keyword]);

  // 初回レンダリング時に全ファイルを取得
  useEffect(() => {
    const fetchAllFiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/files`);
        const sortedFiles = response.data.files.sort((a, b) => b.id - a.id);

        // 各ファイルにアイコン情報を追加
        const filesWithIcons = await Promise.all(
          sortedFiles.map(async (file) => {
            try {
              const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/userbyname/${file.user_name}`);
              const userIcon = userResponse.data.user.profile_image || UserIconSample;
              return { ...file, user_icon: userIcon };
            } catch (error) {
              console.error(`Error fetching icon for user ${file.user_name}:`, error);
              return { ...file, user_icon: UserIconSample };
            }
          })
        );

        setFiles(filesWithIcons);
        setFilteredFiles(filesWithIcons);
      } catch (error) {
        console.error('Error fetching all files:', error);
        setError('ファイルの取得に失敗しました');
      }
    };

    fetchAllFiles();
  }, []);

  useEffect(() => {
    if (searchContainerRef.current) {
      searchContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch();
  };

  const currentFiles = filteredFiles.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);
  const totalPages = Math.ceil(filteredFiles.length / filesPerPage);

  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    if (resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="search-container" ref={searchContainerRef}>
      <h2>教材検索</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="SchoolType">学校種別</label>
        <select value={schoolType} onChange={e => setSchoolType(e.target.value)} className="select">
          <option value="">----学校種別を選択----</option>
          {schoolTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>

        <hr />

        <label htmlFor="Subject">教科</label>
        <select value={subject} onChange={e => setSubject(e.target.value)} className="select">
          <option value="">----教科を選択----</option>
          {subjects.map((subj, index) => (
            <option key={index} value={subj}>{subj}</option>
          ))}
        </select>

        <hr />

        <label htmlFor="FileType">ファイル形式</label>
        <select value={fileType} onChange={e => setFileType(e.target.value)} className="select">
          <option value="">--ファイル形式を選択--</option>
          {fileTypes.map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>

        <hr />

        <label htmlFor="Keyword">フリーワード</label>
        <input
          type="text"
          id="Keyword"
          value={keyword}
          onChange={e => setKeyword(e.target.value)}
          className="freeword"
          placeholder="ファイル名を入力"
        />
        <div>
          <button type="submit">教材を検索する</button>
        </div>
      </form>

      <div ref={resultsRef}>
        <div className='search-result'>検索結果</div>
        <FileList files={currentFiles} />

        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {(currentPageIndex + 1) * filesPerPage < filteredFiles.length && (
          <p onClick={() => goToPage(currentPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}
      </div>
    </div>
  );
};

export default MaterialSearch;
