import { useEffect } from 'react';  // useEffectをインポート
import './PreRegistration.css';  

function PreRegistration() {

    // 初回レンダリング時に自動で一番上にスクロールする処理
    useEffect(() => {
      window.scrollTo(0, 0);  // ページの一番上にスクロール
    }, []);  // 空の依存配列で初回レンダリング時のみ実行

  return (
    
    <div className="pre-registration">
      <>
      仮登録が完了しました。<br /><br />
      メールを確認し、送信されたリンクをクリックして本登録を完了してください。
      </>
    </div>
  );
}

export default PreRegistration;