import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './ConfirmEmail.css';  

function ConfirmEmail() {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');  
    const [isConfirmed, setIsConfirmed] = useState(false);  // 一度確認済みなら停止
  
    useEffect(() => {
      // もし既に確認済みであれば、何もしない
      if (isConfirmed) return;
  
      const query = new URLSearchParams(location.search);
      const token = query.get('token');
      const email = query.get('email');
  
      if (token && email) {
        axios.get(`${process.env.REACT_APP_API_URL}/confirm-email`, {
          params: { token, email }
        })
          .then(response => {
            setMessage(
              <>
                本登録が完了しました。<br />
                ログインページにリダイレクトします。
              </>
            );
            setIsConfirmed(true);  // 確認済みにセットし、リクエストを停止
            setTimeout(() => {
              navigate('/login');  // 3秒後にログインページへリダイレクト
            }, 3000);
          })
          .catch(error => {
            if (error.response && error.response.status === 400) {
              setMessage('リンクが無効か、すでに使用されています。');
            } else {
              setMessage('確認に失敗しました。サーバーエラーが発生しました。');
            }
          });
      } else {
        setMessage('無効なリンクです。');
      }
    }, [location.search, isConfirmed, navigate]);

  return (
    
    <div className="confirm-email">
      <h2>メール認証ページ</h2>
      <div className='confirm-email-message'><h3>{message}</h3></div>
    </div>
  );
}

export default ConfirmEmail;