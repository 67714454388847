// VisitorTracker.js
import { useEffect } from 'react';
import axios from 'axios';

function VisitorTracker() {
  useEffect(() => {
    const trackVisit = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/track-visit`)
        .then(() => console.log('Visit counted'))
        .catch(error => console.error('Error counting visit:', error));
    };

    // 初回にトリガー
    trackVisit();

    // 1分ごとにエンドポイントを呼び出す
    const interval = setInterval(trackVisit, 60000);

    // クリーンアップ: コンポーネントがアンマウントされるときにintervalをクリア
    return () => clearInterval(interval);
  }, []);

  return null; // UIを表示しないため、nullを返す
}

export default VisitorTracker;
