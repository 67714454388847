// src/components/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // スタイルを作成する場合に使用

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>プライバシーポリシー</h1>
      <div className="privacy-policy-message">
        このプライバシーポリシーは、EduShareプラットフォームにおいて、利用者者間の個人情報の取り扱いについて説明するものです。EduShareは、利用者様のプライバシーを尊重し、適切な管理を行います。
      </div>

      <h3>1. 収集する情報</h3>
      <div className="privacy-policy-message">
        当サイトでは、ユーザー登録時やサービス利用時に以下の情報を収集します:
        <ul>
          <li>氏名</li>
          <li>メールアドレス</li>
          <li>生年月日</li>
          <li>アカウントに関するその他の情報</li>
          <li>教育資料のアップロード・ダウンロード履歴</li>
          <li>お気に入り登録情報</li>
          <li>クッキーを使用した行動データ</li>
        </ul>
        機微な個人情報（人種、宗教、健康状態など）は一切収集しません。
      </div>

      <h3>2. 情報の利用目的</h3>
      <div className="privacy-policy-message">
        当サイトで収集した個人情報は、以下の目的で利用されます:
        <ul>
          <li>プラットフォームの基本機能（ログイン、資料のアップロード・ダウンロード等）の提供</li>
          <li>ユーザーサポートの提供</li>
          <li>サービスの品質向上および新機能の開発</li>
          <li>サイトの利用状況の分析によるユーザーエクスペリエンスの向上</li>
          <li>セキュリティ対策および不正利用の防止</li>
          <li>ユーザーの承諾を得た上でのマーケティング・通知の送信</li>
        </ul>
      </div>

      <h3>3. 情報の共有</h3>
      <div className="privacy-policy-message">
        利用者様の個人情報を第三者に販売、貸与、共有することはありません。ただし、以下の場合に限り、必要最小限の情報を第三者と共有することがあります:
        <ul>
          <li>法律に基づく要求があった場合</li>
          <li>サイトの運営や機能向上のために、外部のサービスプロバイダー（例：ホスティング、データ解析サービス）に委託する場合</li>
        </ul>
        この場合も、委託先は利用者様の個人情報を厳重に保護し、適切に管理します。
      </div>

      <h3>4. クッキーおよび追跡技術の使用</h3>
      <div className="privacy-policy-message">
        当サイトでは、ユーザーの利便性向上と利用状況の分析のために、クッキーや類似の追跡技術を使用しています。クッキーにより収集される情報には、サイトの閲覧履歴や利用傾向が含まれます。
        <br />
        クッキーの種類:
        <ul>
          <li>セッションクッキー: ブラウザを閉じると自動的に削除されます。</li>
          <li>パーシステントクッキー: ユーザーが削除するまで保存されます。</li>
        </ul>
        ユーザーは、ブラウザの設定でクッキーを無効にすることができますが、クッキーを無効にすると、サイトの一部機能が制限される可能性があります。
      </div>

      <h3>5. データの保護と管理</h3>
      <div className="privacy-policy-message">
        利用者様の個人情報は、適切な技術的および組織的対策によって保護されます。データは暗号化された形で保存され、送信時もSSL/TLSにより暗号化されます。不正アクセス、データの紛失、改ざん、漏洩を防ぐために、業界標準のセキュリティ対策を採用しています。
      </div>

      <h3>6. ユーザーの権利</h3>
      <div className="privacy-policy-message">
        利用者様は、以下の権利を有しています:
        <ul>
          <li>個人情報へのアクセス: 利用者様が提供した個人情報を確認することができます。</li>
          <li>訂正の要求: 利用者様の個人情報に誤りがある場合、訂正を要求することができます。</li>
          <li>削除の要求: 利用者様のアカウントや個人情報の削除を要求することができます。ただし、法的に保存が必要な情報は対象外となる場合があります。</li>
        </ul>
      </div>

      <h3>7. プライバシーポリシーの変更</h3>
      <div className="privacy-policy-message">
        当プライバシーポリシーは、法令の改正やサービス内容の変更に伴い、必要に応じて改訂されることがあります。変更があった場合は、サイト上で通知します。
      </div>

      <h3>8. お問い合わせ先</h3>
      <div className="privacy-policy-message">
        プライバシーポリシーに関するご質問や個人情報の取り扱いに関するお問い合わせは、以下の連絡先までご連絡ください。
        <br />
        メールアドレス:edushare.jp@gmail.com
      </div>

      <div className='Last-updated'>最終更新日: 2024年10月19日</div>
    </div>
  );
}

export default PrivacyPolicy;
