import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import './UserPage.css';
import UserIconSample from '../assets/iconsample.png';
import axios from 'axios';
import FileList from './FileList';

const UserPage = () => {
  const { username } = useParams();  // URLからユーザーネームを取得
  const [files, setFiles] = useState([]);
  const [userData, setUserData] = useState({
    profileImage: '',
    username: '',
    email: '',
    profileText: ''
  });
  const [error, setError] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // 現在のページインデックス

  const filesPerPage = 9; // 1ページあたりの表示ファイル数

  // ユーザーデータの取得
  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/userbyname/${username}`);
      if (response.data && response.data.user) {
        const user = response.data.user;
        setUserData({
          ...user,
          profileImage: user.profile_image,
          profileText: user.profile_text,
        });
      } else {
        setError('ユーザーデータが正しく取得できませんでした');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('ユーザー情報の取得に失敗しました');
    }
  }, [username]);

  // ユーザーのファイル一覧を取得し、各ファイルにアイコンを追加
  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/userfilesbyname/${username}`);
      const sortedFiles = (response.data || []).sort((a, b) => b.id - a.id);
      
      // 各ファイルにuser_iconプロパティとしてprofileImageを追加
      const filesWithIcon = sortedFiles.map(file => ({
        ...file,
        user_icon: userData.profileImage || UserIconSample  // ユーザーのアイコンがなければデフォルトアイコン
      }));
      
      setFiles(filesWithIcon);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, [username, userData.profileImage]);

  useEffect(() => {
    fetchUserData();
    fetchFiles();
  }, [fetchUserData, fetchFiles]);

  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    if (pageIndex !== 0) {
      window.scrollTo({ top: 220 });
    }
  };

  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);
  const totalPages = Math.ceil(files.length / filesPerPage);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='userpage'>
      <div className='user-profile'>
        <img 
          src={userData.profileImage || UserIconSample} 
          alt="User Icon" 
          className='user-icon'
        />
        <div className='user-info'>
          <p>ユーザーネーム</p>
          <p>{userData.username}</p>
        </div>
        <div className='profile-sentence'>
          <p>プロフィール</p>
          <p>{userData.profileText || 'プロフィール文が設定されていません。'}</p>
        </div>
      </div>

      <div className="user-material">
        <p>{userData.username}の教材</p>
        <FileList files={currentFiles} showDeleteButton={false} />

        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {(currentPageIndex + 1) * filesPerPage < files.length && (
          <p onClick={() => goToPage(currentPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}
      </div>
    </div>
  );
};

export default UserPage;
