import React from 'react';

const UserList = ({ users, onSelectUser }) => {
  return (
    <ul className="user-list">
      {users.map(user => (
        <li key={user.id} onClick={() => onSelectUser(user.id)}>
          {user.username}
        </li>
      ))}
    </ul>
  );
};

export default UserList;
