// src/components/TermsOfService.js
import React from 'react';
import './TermsOfService.css'; // スタイルを作成する場合に使用

function TermsOfService() {
  return (
    <div className="terms-of-service-container">
      <h1>利用規約</h1>
      <div className="terms-of-service-message">
        　この利用規約（以下「本規約」）は、edushareプラットフォーム（以下「本サービス」）を利用するすべてのユーザー（以下「利用者様」）に適用されます。利用者様は、本規約に同意した上で、本サービスを利用するものとします。
      </div>

      <h3>1. サービスの提供</h3>
      <div className="terms-of-service-message">
        　本サービスは、利用者様が教育資料を共有・アクセスできる場を提供します。サービスの内容や機能は、予告なく変更、追加、または削除される場合があります。本サービスの提供において技術的な問題が生じた場合、修復や改善に努めますが、サービスが中断される可能性があることをご了承ください。
      </div>

      <h3>2. アカウントの作成および管理</h3>
      <div className="terms-of-service-message">
      <li>利用者様が本サービスを利用するには、アカウントを作成する必要があります。利用者様は、正確かつ最新の情報を提供し、常にアカウント情報を正しく保つ責任があります。</li>
        <li>利用者様が登録するパスワードは、ハッシュ化され、暗号化された形で保存されます。パスワードは復元できませんので、忘れないように注意してください。</li>
        <li>アカウントのセキュリティは利用者様の責任です。パスワードやログイン情報の不正利用が発覚した場合、直ちに連絡してください。不正利用による損害については責任を負いかねます。</li>
        <li>利用者様が未成年の場合、親または法定代理人の同意を得ているものとします。</li>
      </div>

      <h3>3. 利用者様の責任と禁止事項</h3>
      <div className="terms-of-service-message">
        　本サービスの利用にあたり、以下の行為を禁止します：
        <ul>
          <li>著作権侵害：他者の著作権、商標、知的財産権を侵害する行為。</li>
          <li>不正アクセス：他人のアカウントを使用したり、不正アクセスやハッキング行為を行うこと。</li>
          <li>違法または不適切なコンテンツの投稿：公序良俗に反する内容や、他者を誹謗中傷するコンテンツの投稿。</li>
          <li>スパムやフィッシング：不正な広告や詐欺行為を行う目的で本サービスを利用すること。</li>
          <li>サーバーやネットワークへの負荷：本サービスの運営に支障をきたす行為やサーバーへの過度な負荷をかける行為。</li>
        </ul>
      </div>

      <h3>4. コンテンツのアップロードに関する注意事項</h3>
      <div className="terms-of-service-message">
       　 利用者様が本サービスにアップロードするコンテンツに関して、以下の点に注意してください：
        <ul>
          <li><strong>個人情報や機密情報の取り扱い</strong><br />アップロードするコンテンツに、他人の個人情報や機密情報が含まれていないか確認してください。これらが含まれる場合、該当コンテンツを削除する権利を有します。</li>
          <li><strong>プライバシーに関する注意</strong><br />個人やグループのプライバシーを侵害する内容はアップロードしないでください。</li>
          <li><strong>品質と正確性</strong><br />アップロードするコンテンツは、正確で信頼性のある情報であることを確認してください。</li>
          <li><strong>ウイルスやマルウェアの禁止</strong><br />ウイルスや有害なソフトウェアを含むファイルのアップロードは禁止されています。</li>
          <li><strong>コンテンツの適切性</strong><br />アップロードするコンテンツは、教育的な目的に適したものでなければなりません。</li>
          <li><strong>定期的なコンテンツの見直し</strong><br />アップロードしたコンテンツは定期的に見直し、必要に応じて更新や削除を行ってください。</li>
          <li><strong>著作権について</strong><br />他者の著作物を無断でアップロードすることは法律違反です。</li>
          <li><strong>教科書の内容について</strong><br />教科書や教育資料は著作権で保護されています。アップロードする際は著作権者の許可を得てください。</li>
          <li><strong>責任について</strong><br />アップロードされたコンテンツの著作権に関しては、利用者様が責任を負います。</li>
        </ul>
      </div>

      <h3>5. コンテンツの所有権および責任</h3>
      <div className="terms-of-service-message">
        　利用者様が本サービスにアップロードするコンテンツは、利用者様がそのコンテンツに対して著作権を有しているか、必要な許可を得ていることを前提とします。
        <br />
        利用者様は、アップロードされたコンテンツに関して一切の責任を負います。違法または不適切と判断されたコンテンツについては、予告なく削除する権利を有します。
        <br />
        　また、アップロードされたデータの一部は、edushare公式SNSアカウント（X（旧Twitter）、Instagramなど）において、教育的な価値を紹介する目的で許可なく紹介させていただく場合がございます。紹介に際して個人情報が含まれないよう、配慮を徹底いたしますが、予めご了承ください。利用者様が本サービスを利用することで、この取り扱いに同意したものとみなします。
      </div>

      <h3>6. 免責事項</h3>
      <div className="terms-of-service-message">
        　本サービスの利用に関連して発生した直接的または間接的な損害、損失について、いかなる場合も責任を負いかねます。本サービス上の情報やコンテンツが正確であることは保証いたしません。利用者様は自己責任で本サービスを利用してください。
      </div>

      <h3>7. 規約の変更</h3>
      <div className="terms-of-service-message">
        　本規約は、随時変更されることがあります。規約が変更された場合、変更内容をサイト上で通知し、利用者様が変更後も本サービスを利用し続けることで、変更に同意したものとみなされます。
      </div>

      <h3>8. 準拠法および管轄</h3>
      <div className="terms-of-service-message">
        　本規約は、日本の法令に基づいて解釈されます。本サービスに関連して生じる紛争は、日本の裁判所が専属的な管轄権を有するものとします。
      </div>

      <div className='Last-updated'>最終更新日: 2024年11月2日</div>
    </div>
  );
}

export default TermsOfService;
