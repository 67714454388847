import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';  // 名前付きエクスポートを使用

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);  // ロード中の状態を管理

  // ログイン状態をチェックする関数
  const checkLoginStatus = () => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        // トークンをデコードして有効期限を確認する（簡易なチェック）
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // 有効期限が切れていないかチェック
        if (decodedToken.exp > currentTime) {
          setIsLoggedIn(true);
        } else {
          // トークンが期限切れなら削除してログアウト状態にする
          localStorage.removeItem('token');
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Invalid token', error);
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
    setLoading(false);  // チェック完了後にロード状態を解除
  };

  // コンポーネントがマウントされた時にログイン状態を確認する
  useEffect(() => {
    checkLoginStatus();
  }, []);

  if (loading) {
    // ログイン状態を確認中に表示するローディング状態のUI（必要に応じて調整）
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
