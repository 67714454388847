import React, { useEffect, useState, useCallback } from 'react';
import './Subject.css';
import axios from 'axios';
import UserIconSample from '../assets/iconsample.png'; 
import FileList from './FileList';

const Subject = ({ schoolType, subject }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const filesPerPage = 9;

  // サーバーからファイル一覧を取得
  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/files`);

      if (!response.data || !Array.isArray(response.data.files)) {
        throw new Error('ファイルデータが無効です');
      }

      // 条件に基づいてファイルをフィルタリング
      let filteredFiles = response.data.files.filter((file) => {
        if (subject) {
          return file.school_type === schoolType && file.subject === subject;
        }
        return file.school_type === schoolType;
      });

      // idの降順でソート
      filteredFiles = filteredFiles.sort((a, b) => b.id - a.id);

      // 各ファイルにアイコン情報を追加
      const filesWithIcons = await Promise.all(
        filteredFiles.map(async (file) => {
          try {
            const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/userbyname/${file.user_name}`);
            const userIcon = userResponse.data.user.profile_image || UserIconSample; // アイコンまたはデフォルトアイコン
            return { ...file, user_icon: userIcon };
          } catch (error) {
            console.error(`Error fetching icon for user ${file.user_name}:`, error);
            return { ...file, user_icon: UserIconSample };
          }
        })
      );

      setFiles(filesWithIcons);
    } catch (error) {
      console.error('ファイルの取得に失敗しました:', error);
      setError('ファイルの取得に失敗しました');
    }
  }, [schoolType, subject]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);
  const totalPages = Math.ceil(files.length / filesPerPage);

  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="subject-page">
      <h2>{schoolType}{subject ? `  ${subject}` : ''}の教材一覧</h2>
      {error && <p>{error}</p>}
      <FileList files={currentFiles} showDeleteButton={false} />

      {totalPages > 1 && (
        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Subject;
