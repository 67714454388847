import React, { useEffect, useState } from 'react';
import './MaterialDetail.css';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Xlogo from '../assets/X_logo.jpg';  
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const pdfWorkerVersion = '3.11.174'; // PDF.jsのバージョン

const MaterialDetail = () => {
  const { id } = useParams(); // URLの:idパラメータを取得
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダル表示用のステート
  const [isLoggedIn, setIsLoggedIn] = useState(false); // ログイン状態の管理
  const [isFavorite, setIsFavorite] = useState(false); // お気に入り状態の管理

  // モーダルの表示・非表示をトグルする関数
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    // ログイン状態を確認する処理
    const checkLoginStatus = async () => {
      try {
        const token = localStorage.getItem('token'); // トークンを取得
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/check-login`, config);
        setIsLoggedIn(response.data.isLoggedIn);
      } catch (error) {
        console.error('Error checking login status:', error);
        setIsLoggedIn(false); // ログインしていない場合はfalse
      }
    };

    // ファイルの取得
    const fetchFile = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/files/${id}`);
        console.log('File data:', response.data); // ここでデータを確認
        setFile(response.data);
        checkFavoriteStatus(response.data.id);
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };
    
    // お気に入り状態を確認
    const checkFavoriteStatus = async (fileId) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/favorites/${fileId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setIsFavorite(response.data.isFavorite);
      } catch (error) {
        console.error('Error checking favorite status:', error);
      }
    };

    checkLoginStatus();
    fetchFile();
  }, [id]);

  // TwitterシェアURLを生成する関数
  const shareOnTwitter = () => {
    if (!file) return;

    const tweetText = `${file.filename}\n投稿日：${new Date(file.upload_date).toLocaleDateString()}\n投稿者：${file.user_name || '投稿者不明'}\n学校種別と教科：${file.school_type} ${file.subject}\n\n教材はこちらから👉 https://edushare.jp/MaterialDetail/${id}\n\n#edushare`;

    // URLエンコードしてTwitterのシェアURLを生成
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
    
    // 新しいタブでTwitterの投稿画面を開く
    window.open(twitterUrl, '_blank');
  };

  // ダウンロードボタンの処理
  const handleDownloadClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // ダウンロードを防止
      alert('会員登録をしたのちログインをしてください。');
    }
  };

  // お気に入りの登録・解除
  const toggleFavorite = async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      alert('お気に入りに登録するにはログインしてください。');
      return;
    }
  
    try {
      if (isFavorite) {
        // お気に入りから削除
        await axios.delete(`${process.env.REACT_APP_API_URL}/favorites/${file.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIsFavorite(false);
        // お気に入り数を減らす
        setFile(prevFile => ({ ...prevFile, favoriteCount: prevFile.favoriteCount - 1 }));
      } else {
        // お気に入りに追加
        await axios.post(`${process.env.REACT_APP_API_URL}/favorites`, { fileId: file.id }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setIsFavorite(true);
        // お気に入り数を増やす
        setFile(prevFile => ({ ...prevFile, favoriteCount: prevFile.favoriteCount + 1 }));
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
      alert('お気に入りの操作に失敗しました。');
    }
  };

  // ファイルプレビューのレンダリング
  const renderFilePreview = (file) => {
    const fileUrl = file?.path || '';

    if (file.file_type?.toLowerCase() === 'pdf') {
      return (
        <div className='previewStyle' onClick={toggleModal}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfWorkerVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={fileUrl} />
          </Worker>
        </div>
      );
    } else if (file.file_type?.toLowerCase() === '画像形式' || file.filename.toLowerCase().endsWith('.ico')) {
      return <img src={fileUrl} alt={file.filename} className='previewStyle' onClick={toggleModal} />;
    } else if (['word', 'excel', 'powerpoint'].includes(file.file_type?.toLowerCase())) {
      return (
        <div className='previewStyle' onClick={toggleModal}>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
            style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
            frameBorder="0"
            title="Office Preview"
          ></iframe>
        </div>
      );
    } else {
      return <div>プレビューはこのファイル形式に対応していません</div>;
    }
  };

  // モーダルの内容をレンダリング
  const renderModalContent = () => {
    const fileUrl = file?.path || '';

    return (
      <div className="modal-content">
        {file.file_type?.toLowerCase() === 'pdf' ? (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfWorkerVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={fileUrl} />
          </Worker>
        ) : file.file_type?.toLowerCase() === '画像形式' || file.filename.toLowerCase().endsWith('.ico') ? (
          <img src={fileUrl} alt={file.filename} style={{ width: '100%', maxHeight: '80vh' }} />
        ) : (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
            style={{ width: '100%', height: '80vh' }}
            frameBorder="0"
            title="Office Preview"
          ></iframe>
        )}
      </div>
    );
  };

  // fileが存在しない場合はローディングやエラーメッセージを表示
  if (!file) return <div>Loading...</div>;

  return (
    <div className="material-detail">
      {/* プレビュー画面 */}
      <div className="file-item">
        <div className="file-info">
          <div className="file-preview">{renderFilePreview(file)}</div>
          <div className="class_a">
            {/* その他の情報 */}
            <div className="item_c">
              <p className="item_filename">{file.filename}</p>
              {/* お気に入りボタン */}
              <div className="favorite-icon" onClick={toggleFavorite} style={{ cursor: 'pointer', display: 'inline-block' }}>
                <span
                  title={isFavorite ? 'お気に入り教材から削除' : 'お気に入り教材に登録'}  // ホバー時のメッセージ
                  style={{
                    color: isFavorite ? 'orange' : 'gray',
                    fontSize: '25px', // アイコンのサイズ
                  }}
                >
                  ★
                </span>
              </div>
            </div>
            <div className="class_b">
              <p className="item_a">学校種別</p>
              <p className="item_b">{file.school_type}</p>
            </div>
            <div className="class_b">
              <p className="item_a">教科</p>
              <p className="item_b">{file.subject}</p>
            </div>
            <div className="class_b">
              <p className="item_a">投稿者</p>
              <p className="item_b">{file.user_name ? <Link to={`/userpage/${file.user_name}`} className='post-user'>{file.user_name}</Link> : '投稿者不明'}</p>  {/* 投稿者が取得できない場合は '投稿者不明' を表示 */}
            </div>
            <div className="class_b">
              <p className="item_a">投稿日</p>
              <p className="item_b">{new Date(file.upload_date).toLocaleDateString()}</p>
            </div>
            <div className="class_b">
              <p className="item_a">お気に入りされた数</p>
              <p className="item_b">{file.favoriteCount ? `${file.favoriteCount} 回` : '0 回'}</p>  {/* 修正: デフォルトを0回に */}
            </div>
            <div className="download_btn">
              {/* ログインしている場合のみダウンロードボタンが有効 */}
              <a
                href={isLoggedIn && file?.path ? file.path : '#'}
                className={`download ${isLoggedIn && file?.path ? '' : 'disabled'}`} // ログインしていない場合はボタンを無効化
                download={file.filename} // データベースのfilenameを使ってタイムスタンプなしのファイル名でダウンロード
                onClick={handleDownloadClick}
              >
                資料をダウンロード
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="share-on-twitter">
        <img 
          src={Xlogo} 
          alt="Share on Twitter" 
          onClick={shareOnTwitter} 
          className="twitter-share-button" 
          style={{ cursor: 'pointer', width: '30px', height: '30px' }}
        />
      </div>

      {/* モーダルの表示 */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            {renderModalContent()}
            <button className="close-modal" onClick={toggleModal}>
              閉じる
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialDetail;
