import React, { useState } from 'react';
import './FileList.css';
import { useNavigate, Link } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const pdfWorkerVersion = '3.11.174'; // PDF.jsのバージョン

const FileList = ({ files, showDeleteButton = false, onDelete }) => {
  const navigate = useNavigate();

  // `.xlsm` のプレビュー用データを管理する状態
  const [xlsmPreviewData, setXlsmPreviewData] = useState({});

  // ファイルごとにプレビューを取得
  const fetchXlsmPreview = async (fileId, filePath) => {
    try {
      const response = await fetch('http://localhost:5000/api/preview-xlsm', { // サーバーURLを明示
        method: 'POST',
        body: JSON.stringify({ path: filePath }),
        headers: { 'Content-Type': 'application/json' },
      });
  
      const data = await response.json();
      setXlsmPreviewData((prev) => ({
        ...prev,
        [fileId]: data.data || [],
      }));
    } catch (error) {
      console.error(`Error fetching preview for file ID ${fileId}:`, error);
    }
  };
  
  // ファイルプレビューのレンダリング
  const renderFilePreview = (file) => {
    const fileUrl = file.path;

    const previewStyle = {
      width: '100%',
      height: '300px',   // プレビューの高さ
      objectFit: 'cover',
      cursor: 'pointer', // クリック可能なカーソルに変更
    };

    const handlePreviewClick = () => {
      navigate(`/MaterialDetail/${file.id}`);
    };

    if (file.file_type === 'PDF') {
      return (
        <div style={previewStyle} onClick={handlePreviewClick}>
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfWorkerVersion}/build/pdf.worker.min.js`}>
            <Viewer fileUrl={fileUrl} />
          </Worker>
        </div>
      );
    } else if (file.file_type === '画像形式' || file.filename.toLowerCase().endsWith('.ico')) {
      return <img src={file.path} alt={file.filename} style={previewStyle} onClick={handlePreviewClick} />;
    } else if (file.file_type === 'Word' || file.file_type === 'Excel' || file.file_type === 'PowerPoint') {
      return (
        <div style={previewStyle} onClick={handlePreviewClick}>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(file.path)}`}
            style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
            frameBorder="0"
            title="Office Preview"
          ></iframe>
        </div>
      );
    } else if (file.filename.toLowerCase().endsWith('.xlsm')) {
      // プレビューがまだロードされていない場合にロードを開始
      if (!xlsmPreviewData[file.id]) {
        fetchXlsmPreview(file.id, file.path);
        return <p>プレビューを読み込んでいます...</p>;
      }

      // プレビュー用のデータをレンダリング
      const sheetData = xlsmPreviewData[file.id];
      return (
        <div style={previewStyle}>
          <table border="1" style={{ width: '100%', height: '100%' }}>
            <tbody>
              {sheetData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div onClick={handlePreviewClick} style={previewStyle}>
          <p>このファイル形式のプレビューはサポートされていません。</p>
          <a href={file.path} target="_blank" rel="noopener noreferrer" download={file.filename}>
            ダウンロード
          </a>
        </div>
      );
    }
  };

  return (
    <div className="main-file-list">
      {files.length > 0 ? (
        files.map((file) => (
          <div key={file.id} className="main-file-item">
            <div className="main-file-info">
              {renderFilePreview(file)}
              <div className="sub-file-icon-info">
                <div className="sub-file-icon">
                  {file.user_icon ? (
                    <Link to={`/userpage/${file.user_name}`}><img src={file.user_icon} alt={`${file.user_name}のアイコン`} className="sub-user-icon" /></Link>
                  ) : (
                    <div className="default-icon">No Icon</div>
                  )}
                </div>
                <div className="sub-file-info">
                  <p>{file.filename}</p>
                  <p>（ {file.school_type} {file.subject} ）</p>
                  <p>投稿日：　{new Date(file.upload_date).toLocaleDateString()}</p>
                  <p>投稿者：　<Link to={`/userpage/${file.user_name}`}>{file.user_name}</Link></p>
                  {/* 削除ボタンの表示を条件付きで行う */}
                  {showDeleteButton && (
                    <div className='delete-btn'>
                      <button onClick={() => onDelete(file.id, file.path)}>削除</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='no-material'>該当の教材はありません。</div>
      )}
    </div>
  );
};

export default FileList;
