import { useEffect, useState, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './AdminPage.css';
import axios from 'axios';
import FileList from './FileList';
import UserIconSample from '../assets/iconsample.png';

const AdminPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [files, setFiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const navigate = useNavigate();
  const filesPerPage = 9;

  // 全ファイルを取得する関数
  const fetchFiles = useCallback(async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/files`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const sortedFiles = response.data.files.sort((a, b) => b.id - a.id);

      // 各ファイルにアイコン情報を追加
      const filesWithIcons = await Promise.all(
        sortedFiles.map(async (file) => {
          try {
            const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/userbyname/${file.user_name}`);
            const userIcon = userResponse.data.user.profile_image || UserIconSample;
            return { ...file, user_icon: userIcon };
          } catch (error) {
            console.error(`Error fetching icon for user ${file.user_name}:`, error);
            return { ...file, user_icon: UserIconSample };
          }
        })
      );

      setFiles(filesWithIcons);
    } catch (error) {
      console.error('ファイルの取得に失敗しました', error);
      setError('ファイルの取得に失敗しました');
    }
  }, []);

  // 全ユーザーを取得する関数
  const fetchUsers = useCallback(async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data.users);
    } catch (error) {
      console.error('ユーザーの取得に失敗しました', error);
      setError('ユーザーの取得に失敗しました');
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/login');
      return;
    }

    // 管理者権限の確認
    axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => {
      const userRole = response.data.user.role;
      if (userRole === 'admin') {
        setIsAdmin(true);

        fetchFiles(token);
        fetchUsers(token);
      } else {
        navigate('/');
      }
    })
    .catch(() => {
      navigate('/login');
    });
  }, [navigate, fetchFiles, fetchUsers]);

  const handleDeleteFile = async (fileId, fileKey) => {
    const confirmDelete = window.confirm('本当に削除しますか？');
    if (confirmDelete) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/delete-file`, {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            fileKey: fileKey,
            fileId: fileId,  
          }
        });
        setFiles(files.filter(file => file.id !== fileId));
        alert('ファイルが削除されました');
      } catch (error) {
        console.error('Error deleting file:', error);
        setError('ファイルの削除に失敗しました');
      }
    }
  };

  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    if (pageIndex !== 0) {
      window.scrollTo({
        top: 220,
      });
    }
  };

  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);
  const totalPages = Math.ceil(files.length / filesPerPage);

  if (!isAdmin) {
    return <div>読み込み中...</div>;
  }

  return (
    <div className='admin-page'>
      {error && <div>{error}</div>}

      <div className="my-material">
        <h3>アップロードされたファイル一覧</h3>

        <FileList files={currentFiles} showDeleteButton={true} onDelete={handleDeleteFile} />

        <div className="pagination-controls">
          {[...Array(totalPages)].map((_, index) => (
            <span
              key={index}
              onClick={() => goToPage(index)}
              className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
            >
              {index + 1}
            </span>
          ))}
        </div>

        {(currentPageIndex + 1) * filesPerPage < files.length && (
          <p onClick={() => goToPage(currentPageIndex + 1)} className="more">
            もっとみる
          </p>
        )}
      </div>

      <h3>ユーザー一覧</h3>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            <strong>ID:</strong> {user.id} / 
            <strong>ユーザーネーム:</strong> 
            <Link to={`/userpage/${user.username}`}>{user.username}</Link> / 
            <strong>メール:</strong> {user.email}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPage;
