import React, { useState } from 'react';
import axios from 'axios';
import './Register.css'; // スタイルファイルをインポート
import { useNavigate } from 'react-router-dom';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

function Register() {
  const [username, setUsername] = useState('');  // ユーザー名の状態を管理
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [year, setYear] = useState('');   // 年
  const [month, setMonth] = useState(''); // 月
  const [day, setDay] = useState('');     // 日
  const [errors, setErrors] = useState({});
  const [passwordStrength, setPasswordStrength] = useState('');
  const [checkedTerms, setCheckedTerms] = useState(false); // 利用規約の同意チェックボックス
  const navigate = useNavigate(); // useNavigateを使ってnavigateを定義

  const validatePasswordStrength = (password) => {
    let strength = '';
    const lengthCheck = password.length >= 8;
    const lowerCaseCheck = /[a-z]/.test(password);
    const upperCaseCheck = /[A-Z]/.test(password);
    const numberCheck = /[0-9]/.test(password);
    const specialCharCheck = /[!@#$%^&*]/.test(password);

    if (lengthCheck && lowerCaseCheck && upperCaseCheck && numberCheck && specialCharCheck) {
      strength = '強い';
    } else if (lengthCheck && (lowerCaseCheck || upperCaseCheck) && (numberCheck || specialCharCheck)) {
      strength = '普通';
    } else {
      strength = '弱い';
    }

    setPasswordStrength(strength);
  };

  const validateForm = () => {
    const newErrors = {};
    if (email !== emailConfirm) {
      newErrors.emailConfirm = 'メールアドレスが一致しません';
    }
    if (password !== passwordConfirm) {
      newErrors.passwordConfirm = 'パスワードが一致しません';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePasswordStrength(newPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // チェックボックスが選択されていない場合にアラートを表示
    if (!checkedTerms) {
      alert('利用規約をお読みになった上、同意してください。');
      return;
    }
  
    if (validateForm()) {
      try {
        // 生年月日が入力されているかチェック
        const birthdate = year && month && day ? `${year}-${month}-${day}` : null; // 未入力ならnull
  
        // ユーザーネームとメールアドレスの重複チェック
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
          username,
          email,
          password,
          birthdate,  // 生年月日をnullまたは文字列として送信
        });
  
        if (response.data.error) {
          // サーバーからのエラーメッセージがあればアラートを表示
          alert(response.data.error);
        } else {
          // 仮登録完了後、確認メールの受信を促す画面へ遷移
          navigate('/PreRegistration');
        }
  
      } catch (error) {
        // サーバーエラー時の処理
        if (error.response && error.response.data.error) {
          alert(error.response.data.error); // エラーメッセージをアラートで表示
        } else {
          alert('仮登録に失敗しました。');
        }
      }
    }
  };
  
  const generateYearOptions = () => {
    const years = [];
    for (let i = 2023; i >= 1900; i--) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };

  const generateMonthOptions = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(<option key={i} value={i}>{i}</option>);
    }
    return months;
  };

  const generateDayOptions = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(<option key={i} value={i}>{i}</option>);
    }
    return days;
  };

  return (
    <div className="register-container">
      <h2>会員登録</h2>
      <form onSubmit={handleSubmit}>
        <div className='register-type'>
          <label htmlFor="username" className='item'>ユーザー名<span className="required">（必須）</span></label>
          <div>
          <input
            type="text"
            id="username"
            name="username"
            className="user-info-input"
            placeholder="ユーザー名"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="new-username" // autoCompleteを変更
            required
          />
          </div>
        </div>

        <hr />

        <div className='register-type'>
          <label htmlFor="email" className='item'>メールアドレス<span className="required">（必須）</span></label>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              className='user-info-input'
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />
            <input
              type="email"
              id="email-confirm"
              name="email-confirm"
              className='user-info-input'
              placeholder="確認のためもう一度入力"
              value={emailConfirm}
              onChange={(e) => setEmailConfirm(e.target.value)}
              autoComplete="email"
              required
            />
            {errors.emailConfirm && <span className="error">{errors.emailConfirm}</span>}
          </div>
        </div>

        <hr />

        <div className='register-type'>
          <label htmlFor="password" className='item'>パスワード<span className="required">（必須）</span></label> 
          <div>
          <span className={`password-strength ${passwordStrength.toLowerCase()}`}>
              パスワードの強度: {passwordStrength}
          </span>
            <input
              type="password"
              id="password"
              name="password"
              className='user-info-input'
              placeholder="パスワード"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              required
            />
            <input
              type="password"
              id="password-confirm"
              name="password-confirm"
              className='user-info-input'
              placeholder="確認のためもう一度入力"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              autoComplete="new-password"
              required
            />
            {errors.passwordConfirm && <span className="error">{errors.passwordConfirm}</span>}
          </div>
        </div>

        <hr />

        <div className='register-type'>
          <label htmlFor="year" className='item'>生年月日（任意）</label>
          <div className="birthdate-container">
          <select name="year" id="year" value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="">西暦</option>
            {generateYearOptions()}
          </select>
          <select name="month" id="month" value={month} onChange={(e) => setMonth(e.target.value)}>
            <option value="">月</option>
            {generateMonthOptions()}
          </select>
          <select name="day" id="day" value={day} onChange={(e) => setDay(e.target.value)}>
            <option value="">日</option>
            {generateDayOptions()}
          </select>
          </div>
        </div>

        <div className="register-message">
          <div className="message-box">
            <TermsOfService/> 
            <PrivacyPolicy/>
          </div>
        </div>

        <div className='terms-of-service-agree'>
          <input
            type="checkbox"
            id="terms-agree"
            className='agree-check'
            checked={checkedTerms}
            onChange={(e) => setCheckedTerms(e.target.checked)}
          />
          <label htmlFor="terms-agree">
            上記の<a href='/TermsOfService' target="_blank">利用規約</a>と<a href='/PrivacyPolicy' target="_blank">プライバシーポリシー</a>に同意した上で登録する。</label>
        </div>

        <div className='submit-btn'>
          <button
            type="submit"
            disabled={!checkedTerms}
            className={!checkedTerms ? 'disabled' : ''}
          >
            登録
          </button>
        </div>
      </form>
    </div>
  );
}

export default Register;
