import React, { useState } from 'react'; // useStateのインポート
import axios from 'axios'; // axiosを使ってサーバーにリクエスト
import './Inquiry.css';  // スタイルファイルをインポート

function Inquiry() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    message: '',
  });

  // ステータスメッセージの状態
  const [status, setStatus] = useState(''); 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // フォームデータをサーバーに送信
      await axios.post(`${process.env.REACT_APP_API_URL}/send-inquiry`, formData);
      setStatus('お問い合わせが送信されました。'); // 成功メッセージをセット
      alert('お問い合わせが送信されました。'); // 成功メッセージのポップアップ
    } catch (error) {
      console.error('Error sending inquiry:', error);
      setStatus('お問い合わせの送信に失敗しました。'); // 失敗メッセージをセット
      alert('お問い合わせの送信に失敗しました。'); // 失敗メッセージのポップアップ
    }
  };

  return (
    <div className="inquiry-container">
      <h2>お問合せフォーム</h2>
      <form onSubmit={handleSubmit}>
        <div className='form-type'>
          <label htmlFor="username">ユーザー名：</label>
          <div className='box'>
            <input 
              type="text" 
              id="username" 
              name="username" 
              placeholder="ユーザー名" 
              value={formData.username}
              onChange={handleChange}
              required 
            />
          </div>
        </div>
        <div className='form-type'>
          <label htmlFor="email">メールアドレス：</label>
          <div className='box'>
            <input 
              type="email" 
              id="email" 
              name="email" 
              placeholder="メールアドレス" 
              value={formData.email}
              onChange={handleChange}
              required 
            />
          </div>
        </div>
        <div className='form-type'>
          <label htmlFor="message">お問合せ内容：</label>
          <div className='box'>
            <textarea
              className="inq-sentence"
              id="message" 
              name="message" 
              placeholder="お問合せ内容を入力してください"
              value={formData.message}
              onChange={handleChange}
              required 
            />
          </div>
        </div>
        <button type="submit">送信する</button>
      </form>
      {status && <p className="status-message">{status}</p>} {/* ステータスメッセージの表示 */}
    </div>
  );
}

export default Inquiry;
