// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className='footer-all'>
        <div className="footer-section">
          <h4>サポート</h4>
          <Link to="/Inquiry" className='footer-sub'>お問合せ</Link>
          <Link to="/FAQ" className='footer-sub'>よくある質問 (FAQ)</Link>
        </div>

        <div className="footer-section">
          <h4>情報</h4>
          <Link to="/PrivacyPolicy" className='footer-sub'>プライバシーポリシー</Link>
          <Link to="/TermsOfService" className='footer-sub'>利用規約</Link>
        </div>
        <div className="footer-section">
        <h4>ソーシャルメディア</h4>
        <a 
          href="https://x.com/edushare_jp" 
          target="_blank" 
          rel="noopener noreferrer"
          class="footer-sub" >Twitter
        </a>
        <div className='footer-sub'>Facebook</div>
      </div>


      </div>
        <div className="footer-section">
          <p>著作権 © 2024 EduShare. All Rights Reserved.</p>
        </div>
    </footer>
  );
}

export default Footer;
