import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';  
import './MyPage.css';  
import UserIconSample from '../assets/iconsample.png';  
import axios from 'axios';
import FileList from './FileList'; 

const MyPage = () => {
  const [files, setFiles] = useState([]);  
  const [favorites, setFavorites] = useState([]);  // お気に入りの教材リスト
  const [userData, setUserData] = useState({
    profileImage: '',
    username: '',
    email: '',
    profileText: '',
    userId: null,
  }); 
  const [isEditing, setIsEditing] = useState(false);  
  const [newProfileImage, setNewProfileImage] = useState(null);  
  const [newProfileImagePreview, setNewProfileImagePreview] = useState(null);  
  const [error, setError] = useState('');  
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // 現在のページインデックス
  const navigate = useNavigate();  

  const filesPerPage = 9; // 1ページあたりの表示ファイル数
  const fileInputRef = useRef(null); // ファイル選択用のinputのref

// APIリクエストでファイル一覧を取得する関数
const fetchFiles = useCallback(async (token, username) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/myfiles`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const userFiles = response.data.filter(file => file.user_name === username);
    const sortedFiles = userFiles.sort((a, b) => b.id - a.id);

    // 各ファイルにアイコン情報を追加
    const filesWithIcons = await Promise.all(
      sortedFiles.map(async (file) => {
        const userIcon = userData.profileImage || UserIconSample;
        return { ...file, user_icon: userIcon };
      })
    );

    setFiles(filesWithIcons);
  } catch (error) {
    console.error('Error fetching files:', error);
    setError('ファイルの取得に失敗しました');
  }
}, [userData.profileImage]);

// お気に入りファイルを取得する関数
const fetchFavorites = useCallback(async (token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/favorites`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // お気に入りの各ファイルにアイコン情報を追加
    const favoritesWithIcons = await Promise.all(
      response.data.map(async (file) => {
        const userIcon = file.user_icon || UserIconSample;
        return { ...file, user_icon: userIcon };
      })
    );

    setFavorites(favoritesWithIcons);
  } catch (error) {
    console.error('Error fetching favorites:', error);
    setFavorites([]); // エラーが発生した場合もお気に入りを空の配列に設定
  }
}, []);

  // 認証されたユーザー情報を取得
  const fetchUserData = useCallback(async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data && response.data.user) {
        setUserData({
          ...response.data.user,
          userId: response.data.user.userId,
        });
        fetchFiles(token, response.data.user.username);
        fetchFavorites(token); // お気に入りを取得
      } else {
        setError('ユーザーデータが正しく取得できませんでした');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('認証エラーが発生しました');
    }
  }, [fetchFiles, fetchFavorites]);

  // 初期データ取得
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('ログインが必要です');
      navigate('/login');
      return;
    }
  
    fetchUserData(token);
  }, [navigate, fetchUserData]);

  const goToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    if (pageIndex !== 0) {
      window.scrollTo({ top: 220 });
    }
  };

  const currentFiles = files.slice(currentPageIndex * filesPerPage, (currentPageIndex + 1) * filesPerPage);
  const totalPages = Math.ceil(files.length / filesPerPage);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewProfileImagePreview(reader.result);  
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('username', userData.username);
      formData.append('email', userData.email);
      formData.append('profileText', userData.profileText);  
      if (newProfileImage) {
        formData.append('profileImage', newProfileImage);
      }
      await axios.put(`${process.env.REACT_APP_API_URL}/profile`, formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
      });
      setIsEditing(false);
      setNewProfileImage(null);
      setNewProfileImagePreview(null);
      alert('プロフィールが更新されました。');
      navigate('/mypage');  
    } catch (error) {
      console.error('Error saving profile data:', error);
      setError('プロフィールの保存に失敗しました。');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNewProfileImage(null);  
    setNewProfileImagePreview(null);  
    navigate('/mypage');
  };

  // パスワード変更の処理
  const handlePasswordChangeClick = () => {
    const currentPassword = window.prompt('現在のパスワードを入力してください');
    if (!currentPassword) return;

    const newPassword = window.prompt('新しいパスワードを入力してください');
    if (!newPassword) return;

    const confirmNewPassword = window.prompt('新しいパスワード（確認）を入力してください');
    if (!confirmNewPassword) return;

    if (newPassword !== confirmNewPassword) {
      alert('新しいパスワードが一致しません');
      return;
    }

    const token = localStorage.getItem('token');

    // パスワード変更のAPIリクエスト
    axios.post(`${process.env.REACT_APP_API_URL}/change-password`, {
      currentPassword,
      newPassword
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(() => {
      alert('パスワードが変更されました');
    })
    .catch((error) => {
      console.error('Error changing password:', error);
      alert('パスワードの変更に失敗しました');
    });
  };

  // メールアドレス変更の処理
  const handleEmailChangeClick = () => {
    const currentEmail = userData.email;
    const newEmail = window.prompt('現在のメールアドレス: ' + currentEmail + '\n新しいメールアドレスを入力してください');
    
    if (!newEmail || newEmail === currentEmail) {
      alert('メールアドレスが変更されていません');
      return;
    }

    const token = localStorage.getItem('token');

    // メールアドレス変更のAPIリクエスト
    axios.post(`${process.env.REACT_APP_API_URL}/change-email`, {
      newEmail
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(() => {
      alert('メールアドレスが変更されました');
      setUserData({ ...userData, email: newEmail }); // ローカルのユーザーデータを更新
    })
    .catch((error) => {
      console.error('Error changing email:', error);
      alert('メールアドレスの変更に失敗しました');
    });
  };

  const handleDeleteFile = async (fileId, fileKey) => {
    const confirmDelete = window.confirm('本当に削除しますか？');
    if (confirmDelete) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/delete-file`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { fileKey, fileId }
        });
        setFiles(files.filter(file => file.id !== fileId));
        alert('ファイルが削除されました');
      } catch (error) {
        console.error('Error deleting file:', error);
        setError('ファイルの削除に失敗しました');
      }
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='my-page'>
      <div className='my-profile'>
        <img 
          src={newProfileImagePreview || (userData && userData.profileImage ? userData.profileImage : UserIconSample)} 
          alt="User Icon" 
          className='my-icon'
          onClick={isEditing ? handleImageClick : null}  
          style={{ cursor: isEditing ? 'pointer' : 'default' }}  
        />
        {isEditing && (
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleProfileImageChange}
            style={{ display: 'none' }}  
            accept="image/*"
          />
        )}
        <div className='user-info'>
          <p>ユーザーネーム</p>
          <p>
          {isEditing ? (
            <input 
              className="myname-input"
              value={userData.username} 
              onChange={(e) => setUserData({...userData, username: e.target.value})}
            />
          ) : (
            userData.username
          )}
          </p>
        </div>
        <div className='profile-sentence'>
          <p>プロフィール</p>
          <p>
            {isEditing ? (
              <textarea 
                className="profile-textarea"
                value={userData.profileText} 
                onChange={(e) => setUserData({...userData, profileText: e.target.value})}
              />
            ) : (
              userData.profileText || 'プロフィール文が設定されていません。'
            )}
          </p>
        </div>
      </div>

      {isEditing && (
        <div>
          <div className="mypage-btns1">
            <button className="mypage-btn-a" onClick={handlePasswordChangeClick}>パスワードを変更する</button>
          </div>
          <div className="mypage-btns1">
            <button className="mypage-btn-a" onClick={handleEmailChangeClick}>メールアドレスを変更する</button>
          </div>
          <div className="mypage-btns2">
            <button onClick={handleSave} className="mypage-btn">保存</button>
            <button onClick={handleCancel} className="mypage-btn">マイページに戻る</button>
          </div>
        </div>
      )}

      {!isEditing && (
        <>
          <div className="mypage-btns2">
            <button onClick={() => navigate('/Upload')} className="mypage-btn">資料をアップロード</button>
            <button onClick={handleEditClick} className="mypage-btn">プロフィールを編集</button>
          </div>

          <div className="my-material">
            <p>{userData.username}がアップロードした教材</p>
            <FileList files={currentFiles} showDeleteButton={true} onDelete={handleDeleteFile} />

            <div className="pagination-controls">
              {[...Array(totalPages)].map((_, index) => (
                <span
                  key={index}
                  onClick={() => goToPage(index)}
                  className={`page-number ${index === currentPageIndex ? 'active' : ''}`}
                >
                  {index + 1}
                </span>
              ))}
            </div>

            {(currentPageIndex + 1) * filesPerPage < files.length && (
              <p onClick={() => goToPage(currentPageIndex + 1)} className="more">もっとみる</p>
            )}

            <p>お気に入り登録した教材</p>
            {favorites.length > 0 ? (
              <FileList files={favorites} showDeleteButton={false} />
            ) : (
              <div className='not-favorite'>お気に入り登録した教材はありません。</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MyPage;
