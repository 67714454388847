// src/components/FAQ.js
import React from 'react';
import './FAQ.css'; // スタイルを作成する場合に使用

function FAQ() {
  return (
    <div className="faq-container">
      <h1>よくある質問 (FAQ)</h1>

      <div className="faq-item">
        <h3>Q: edushareとは何ですか？</h3>
        <div className="faq-message">
          A: edushareは、教育者や学生が教材や資料を簡単に共有できるオンラインプラットフォームです。教育に関わる様々な資料をアップロード・ダウンロードすることができ、学習をサポートします。
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: どのように資料をアップロードできますか？</h3>
        <div className="faq-message">
          A: ログイン後、マイページから資料をアップロードすることが可能です。ファイル形式はPDFやWord、PowerPointなどに対応しており、簡単にアップロードできます。
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: アップロードした資料は誰に見られますか？</h3>
        <div className="faq-message">
          A: アップロードされた資料は基本的に公開されており、他のユーザーが閲覧・ダウンロードすることが可能です。公開範囲についてのオプションは現在提供されていません。
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: 資料を削除したい場合はどうすれば良いですか？</h3>
        <div className="faq-message">
          A: マイページの資料管理セクションから、いつでもアップロードした資料を削除することができます。
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: 会員登録やログインしなくても、ファイルを閲覧することは可能ですか？</h3>
        <div className="faq-message">
          A: はい、edushareでは会員登録やログインを行わなくても、公開された資料を閲覧することが可能です。ただし、ファイルのアップロードやダウンロード、お気に入り登録を行うには会員登録とログインが必要です。
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: 会員登録しログインすることで何ができるようになりますか？</h3>
        <div className="faq-message">
          A: 会員登録を行いログインすると、以下の機能が利用可能になります：
          <ul>
            <li>ファイルのアップロード</li>
            <li>他のユーザーがアップロードしたファイルのダウンロード</li>
            <li>資料のお気に入り登録と管理</li>
          </ul>
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: どのようなファイル形式をアップロードできますか？</h3>
        <div className="faq-message">
          A: edushareでは、以下のファイル形式のみアップロードが可能です：
          <ul>
            <li>画像ファイル: .jpg, .jpeg, .png, .gif, .bmp, .svg</li>
            <li>Microsoft Officeファイル: .doc, .docx, .xls, .xlsx, .ppt, .pptx</li>
            <li>その他の関連ファイル形式: .pdf, .csv, .json, .zip, .htm</li>
          </ul>
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: アップロードできるファイルサイズに制限はありますか？</h3>
        <div className="faq-message">
          A: 現在、1ファイルあたりのアップロードサイズは最大100MBに制限されています。サイズが大きいファイルの場合は、圧縮などの方法をご検討ください。
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: 資料のアップロードに際して、注意すべき点はありますか？</h3>
        <div className="faq-message">
          A: アップロードする資料には、第三者の個人情報や著作権で保護されたコンテンツが含まれないように注意してください。これらの情報を含むファイルは削除される可能性があります。
        </div>
      </div>

      <div className="faq-item">
        <h3>Q: edushareを利用するために費用はかかりますか？</h3>
        <div className="faq-message">
          A: edushareは現在すべてのサービスを無料で提供しています。
        </div>
      </div>

    </div>
  );
}

export default FAQ;
